import { History } from "history";
import models from "@aeo/core/models";

export type CurrentTab =
  | "details"
  | "pre-audit"
  | "audit"
  | "authorization"
  | "re-audit";

export interface ApplicationStates {
  history: History;
  url: string;
}

export type ApplicationSteps = (
  value: ApplicationStates,
) => Record<CurrentTab, models.SelectCurrentStatus[]>;

export const applicationStatesByStep: ApplicationSteps = ({
  history,
  url,
}) => ({
  details: [
    {
      value: models.StateAction.PRE_AUDIT,
      name: "Primire spre examinare prealabilă",
      modal: false,
      method: "post",
      enabled: [models.ApplicationState.registered],
    },
    {
      value: models.StateAction.SUSPEND,
      name: "Suspendare",
      modal: true,
      method: "patch",
      period: true,
      templateId: "details_suspend",
      enabled: [models.ApplicationState.registered],
    },
    {
      value: models.StateAction.RESUME,
      name: "Reluare",
      modal: true,
      method: "patch",
      templateId: "details_resume",
      enabled: [models.ApplicationState.suspend_registered],
    },
    {
      value: models.StateAction.REJECT,
      modal: true,
      method: "patch",
      name: "Respingere",
      templateId: "details_reject",
      enabled: [
        models.ApplicationState.suspend_registered,
        models.ApplicationState.registered,
      ],
    },
    {
      value: models.StateAction.REVOKE,
      name: "Retragere",
      modal: true,
      method: "patch",
      templateId: "details_revoke",
      enabled: [
        models.ApplicationState.suspend_registered,
        models.ApplicationState.registered,
      ],
    },
  ],
  "pre-audit": [
    {
      value: models.StateAction.SUSPEND,
      name: "Suspendare",
      modal: true,
      method: "patch",
      period: true,
      templateId: "pre_audit_suspend",
      enabled: [models.ApplicationState.pre_audit],
    },
    {
      value: models.StateAction.RESUME,
      name: "Reluare",
      modal: true,
      method: "patch",
      templateId: "pre_audit_resume",
      enabled: [models.ApplicationState.suspend_pre_audit],
    },
    {
      value: models.StateAction.REJECT,
      modal: true,
      method: "patch",
      name: "Respingere",
      templateId: "pre_audit_reject",
      enabled: [
        models.ApplicationState.pre_audit,
        models.ApplicationState.suspend_pre_audit,
      ],
    },
    {
      value: models.StateAction.REVOKE,
      name: "Retragere",
      modal: true,
      method: "patch",
      templateId: "pre_audit_revoke",
      enabled: [
        models.ApplicationState.pre_audit,
        models.ApplicationState.suspend_pre_audit,
      ],
    },
    {
      value: models.StateAction.AUDIT,
      name: "Primire spre examinare",
      modal: false,
      method: "post",
      enabled: [models.ApplicationState.pre_audit],
      onSuccess: () => history.push(`${url}/audit`),
    },
  ],
  audit: [
    {
      value: models.StateAction.SUSPEND,
      name: "Suspendare",
      modal: true,
      method: "patch",
      period: true,
      templateId: "audit_suspend",
      enabled: [models.ApplicationState.audit],
    },
    {
      value: models.StateAction.RESUME,
      name: "Reluare",
      modal: true,
      method: "patch",
      templateId: "audit_resume",
      enabled: [models.ApplicationState.suspend_audit],
    },
    // {
    //   value: models.StateAction.REVOKE,
    //   name: "Retragere",
    //   modal: true,
    //   method: "patch",
    //   templateId: "audit_revoke",
    //   enabled: [
    //     models.ApplicationState.audit,
    //     models.ApplicationState.suspend_audit,
    //   ],
    // },
    {
      value: models.StateAction.AUDIT_ACTIVE,
      name: "Acordare autorizație",
      modal: true,
      method: "post",
      endpoint: "audit",
      templateId: "audit_active",
      enabled: [models.ApplicationState.audit],
    },
    {
      value: models.StateAction.AUDIT_REJECT,
      name: "Refuzare autorizație",
      modal: true,
      method: "post",
      endpoint: "audit",
      templateId: "audit_reject",
      enabled: [
        models.ApplicationState.audit,
        models.ApplicationState.suspend_audit,
      ],
    },
  ],
  authorization: [
    // TODO: Reexamination takes place on creating control order in re-audit
    // {
    //   value: models.StateAction.RE_AUDIT_CONTROL_ORDER,
    //   name: "Reexaminare conditii",
    //   method: "post",
    // },
    {
      name: "Suspendare autorizație",
      modal: true,
      orders: [
        {
          name: "Informare preavizare suspendare",
          value: models.StateAction.SUSPEND_INFO,
          method: "post",
          templateId: "authorization_info_suspend",
        },
        {
          name: "Ordin suspendare autorizație",
          method: "patch",
          period: true,
          value: models.StateAction.SUSPEND,
          templateId: "authorization_suspend",
        },
      ],
      enabled: [models.ApplicationState.active],
    },
    {
      value: models.StateAction.RESUME,
      name: "Reluare autorizație",
      method: "patch",
      modal: true,
      templateId: "authorization_resume",
      enabled: [models.ApplicationState.suspend_active],
    },
    {
      name: "Retragere autorizație",
      modal: true,
      orders: [
        {
          name: "Informare scrisoare retragere",
          value: models.StateAction.WITHDRAW_INFO,
          templateId: "authorization_info_withdraw",
          method: "post",
        },
        {
          name: "Ordin de retragere",
          templateId: "authorization_withdraw",
          method: "patch",
          value: models.StateAction.WITHDRAW,
        },
      ],
      enabled: [
        models.ApplicationState.active,
        models.ApplicationState.suspend_active,
      ],
    },
    {
      name: "Revocare autorizație",
      modal: true,
      orders: [
        {
          name: "Informare scrisoare revocare",
          value: models.StateAction.REVOKE_INFO,
          method: "post",
          templateId: "authorization_info_revoke",
        },
        {
          name: "Ordin de revocare",
          value: models.StateAction.REVOKE,
          method: "patch",
          templateId: "authorization_revoke",
        },
      ],
      enabled: [
        models.ApplicationState.active,
        models.ApplicationState.suspend_active,
      ],
    },
    {
      value: models.StateAction.ANNUL,
      name: "Anulare autorizație",
      method: "patch",
      modal: true,
      templateId: "authorization_annul",
      enabled: [models.ApplicationState.active],
    },
    {
      name: "Schimbare tip autorizație",
      modal: true,
      orders: [
        {
          name: models.AEOType.AEOC,
          changeType: [models.AEOType.AEOF],
          method: "patch",
          templateId: "authorization_type_aeoc",
        },
        {
          name: models.AEOType.AEOS,
          changeType: [models.AEOType.AEOF],
          method: "patch",
          templateId: "authorization_type_aeos",
        },
        {
          name: models.AEOType.AEOF,
          method: "patch",
          changeType: [models.AEOType.AEOC, models.AEOType.AEOS],
          templateId: "authorization_type_aeof",
        },
      ],
      enabled: [models.ApplicationState.active],
    },
  ],

  "re-audit": [
    {
      name: "Suspendare autorizație",
      modal: true,
      orders: [
        {
          name: "Informare preavizare suspendare",
          value: models.StateAction.SUSPEND_INFO,
          method: "post",
          templateId: "authorization_info_suspend",
        },
        {
          name: "Ordin suspendare autorizație",
          method: "patch",
          period: true,
          value: models.StateAction.SUSPEND,
          templateId: "authorization_suspend",
        },
      ],
      enabled: [models.ApplicationState.re_audit],
    },
    {
      value: models.StateAction.RESUME,
      name: "Reluare",
      method: "patch",
      modal: true,
      templateId: "re_audit_resume",
      enabled: [models.ApplicationState.suspend_re_audit],
    },
    {
      name: "Retragere autorizație",
      modal: true,
      orders: [
        {
          name: "Informare scrisoare retragere",
          value: models.StateAction.WITHDRAW_INFO,
          templateId: "authorization_info_withdraw",
          method: "post",
        },
        {
          name: "Ordin de retragere",
          templateId: "authorization_withdraw",
          method: "patch",
          value: models.StateAction.WITHDRAW,
        },
      ],
      enabled: [
        models.ApplicationState.re_audit,
        models.ApplicationState.suspend_re_audit,
      ],
    },
    {
      name: "Revocare autorizație",
      modal: true,
      orders: [
        {
          name: "Informare scrisoare revocare",
          value: models.StateAction.REVOKE_INFO,
          method: "post",
          templateId: "authorization_info_revoke",
        },
        {
          name: "Ordin de revocare",
          value: models.StateAction.REVOKE,
          method: "patch",
          templateId: "authorization_revoke",
        },
      ],
      enabled: [
        models.ApplicationState.re_audit,
        models.ApplicationState.suspend_re_audit,
      ],
    },
    {
      value: models.StateAction.RE_AUDIT_ANNUL,
      name: "Anulare autorizație",
      method: "patch",
      endpoint: "re-audit",
      modal: true,
      templateId: "re_audit_annul",
      enabled: [models.ApplicationState.re_audit],
    },
    {
      value: models.StateAction.RE_AUDIT_ACTIVE,
      method: "post",
      endpoint: "re-audit",
      name: "Acordare Autorizație",
      enabled: [models.ApplicationState.re_audit],
    },
  ],
});
